import type { AddressValidationResult } from './types';

export const USER_EDIT_PROFILE = 'USER/EDIT_PROFILE' as const;
export const editProfile = (customerData: { id: string } & Record<string, any>) => ({
  type: USER_EDIT_PROFILE,
  payload: { customerData },
});

export const USER_EDIT_PROCESSED = 'USER/EDIT_PROCESSED';
export const processedEditProfile = (addressValidationResult : AddressValidationResult) => ({
  type: USER_EDIT_PROCESSED,
  payload: { addressValidationResult },
});

export type EditProfileAction = ReturnType<typeof editProfile | typeof processedEditProfile>;
