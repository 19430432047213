import loadable from '@loadable/component';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RouteName } from 'routes';
import { CookieBarModes } from 'behavior/settings/constants';
import { LoadingIndicator } from 'components/primitives/loadingIndicator';
import { Header, Footer, Extras, Modals } from 'components/sections';
import { HashRouter } from 'components/hash';
import LayoutShiftProvider from './LayoutShiftProvider';
import PrintControls from './PrintControls';
import { ScrollToTopButton } from 'components/primitives/buttons';
import { OfflineModeSupport } from 'behavior/app';
import { Profiler } from 'components/tools/profiler';
import { AriaStatus } from 'components/objects/ariaStatus';
import { Widget as ProductComparisonWidget } from 'components/objects/productComparison';
import { NotValidCustomerWarning } from 'components/objects/notValidCustomer';
import { AccountImpersonationBar } from 'components/objects/adminImpersonation';
import SelfPostingForm from 'components/objects/SelfPostingForm';
import { useOnChange, useIsInitialNavigation } from 'utils/hooks';
import { setFocusWithoutScrolling } from 'utils/dom';
import { merge, of } from 'rxjs';
import { startWith, switchMap, delay, pairwise, filter, map, takeUntil, take } from 'rxjs/operators';
import { useServices } from 'utils/services';
import { scroll$, orientationChange$, resize$, useEventObservable } from 'utils/rxjs';
import { saveScrollPosition } from 'behavior/routing';
import { isVisualDesigner } from 'behavior/visualDesigner';

const LimitedAccess = loadable(() => import(/*webpackChunkName:"limited"*/'components/objects/limitedAccess'));
const OfflinePage = loadable(() => import(/*webpackChunkName:"offline"*/'components/objects/offlineMode'));
const CookieBar = loadable(() => import(/*webpackChunkName:"cookie-bar"*/'components/objects/cookieBar'));

const Layout = ({
  routeData,
  children,
  isVisualDesigner,
  isPrintMode,
  pageLoadedTime,
  showScrollTop,
  cookieBarDisplayMode,
  limitedAccessMode,
  offline,
  appLoaded,
  emptyLayout,
  postForm,
  headerLoaded,
  footerLoaded,
  hashPresent,
  omitScroll,
  initialScrollPosition,
  saveScrollPosition,
}) => {
  const routeName = routeData ? routeData.routeName : RouteName.NotFound;
  const showLimitedAccessMode = limitedAccessMode || routeName === 'LimitedAccess';

  const layoutRef = useRef();
  const footerRef = useRef();
  const activeElRef = useRef();

  const { api } = useServices();
  const initialNavigation = useIsInitialNavigation();

  const handleScrollPositionSaving = () => {
    if (window.history.state?.state?.scrollPosition?.current) {
      const { value, screenWidth } = window.history.state.state.scrollPosition.current;
      if (screenWidth === window.innerWidth && value === window.pageYOffset)
        return;
    }

    saveScrollPosition(window.pageYOffset, window.innerWidth);
  };

  useEventObservable(scroll$, handleScrollPositionSaving);

  useEffect(() => {
    const className = 'mouse-click';

    const add = () => void (document.documentElement.classList.add(className));
    const remove = e => {
      if (e.key === 'Tab')
        document.documentElement.classList.remove(className);
    };

    add();
    window.addEventListener('mousedown', add);
    window.addEventListener('touchstart', add);
    window.addEventListener('keydown', remove);

    return () => {
      window.removeEventListener('mousedown', add);
      window.removeEventListener('touchstart', add);
      window.removeEventListener('keydown', remove);
    };
  }, []);

  useOnChange(() => {
    if (!layoutRef.current || initialNavigation || omitScroll)
      return;

    if (document.activeElement === document.body || document.activeElement === layoutRef.current)
      return;

    activeElRef.current = document.activeElement;
  }, [pageLoadedTime], false);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    // Restores scroll position when back/forwards history navigation triggers application restoring from browser cache in Firefox and Safari.
    // In this case application execution is been paused when added to cache and it is resumed on restoring, scroll position is been set to top.
    // Happens mostly when navigating back to app from external resource.
    const handlePageShow = e => {
      if (!e.persisted)
        return;

      if (!window.history.state?.state?.scrollPosition?.current)
        return;

      const { value: scrollToPosition, screenWidth } = window.history.state.state.scrollPosition?.current;
      if (screenWidth !== window.innerWidth)
        return;

      window.scrollTo({ top: scrollToPosition, left: 0, behavior: 'smooth' });
    };

    window.addEventListener('pageshow', handlePageShow);
    return () => window.removeEventListener('pageshow', handlePageShow);
  }, []);

  useEffect(() => {
    if (!pageLoadedTime || isVisualDesigner || hashPresent)
      return;

    if (omitScroll) {
      handleScrollPositionSaving();
      return;
    }

    const { value: scrollToPosition, screenWidth } = initialScrollPosition || window.history.state?.state?.scrollPosition?.current || {};

    if (!initialNavigation && window.pageYOffset !== 0)
      window.scrollTo(0, 0);

    if (!scrollToPosition || screenWidth !== window.innerWidth)
      return;

    const subscription = api.isReady$.pipe(
      startWith(false),
      switchMap(ready => ready ? of(ready).pipe(delay(500)) : of(ready)),
      pairwise(),
      filter(([prev, curr]) => prev !== curr && curr),
      map(([_, curr]) => curr),
      takeUntil(merge(resize$, orientationChange$)),
      take(1),
    ).subscribe(() => {
      // In case some element was focused and its (or its ancestor) positioning is not fixed, element will be scrolled into view,
      // so no scroll position restoration required.
      if (
        document.activeElement
        && document.activeElement !== document.body
        && document.activeElement !== layoutRef.current
        && !isFixedOnScreen(document.activeElement)
      )
        return;

      const DOCUMENT_HEIGHT_DRIFT_PIXELS = 1;
      if (scrollToPosition > document.body.scrollHeight - window.innerHeight + DOCUMENT_HEIGHT_DRIFT_PIXELS || scrollToPosition === window.pageYOffset)
        return;

      window.scrollTo({ top: scrollToPosition, left: 0, behavior: 'smooth' });
    });

    return () => subscription.unsubscribe();
  }, [pageLoadedTime]);

  useEffect(() => {
    if (initialNavigation || omitScroll)
      return;

    if (activeElRef.current) {
      // Focus layout element after navigation was finished in case focus was not set manually on any child element during render.
      if (activeElRef.current === document.activeElement) {
        setFocusWithoutScrolling(layoutRef.current);
        /* NVDA screen reader starts reading all text content of focused element in case if previously focused element is no longer present
        in the acessibility tree. This can happen if element is no longer present in DOM or it is hidden via 'display: none' style
        or if it is hidden with 'aria-hidden="true"' attribute. Assertive aria-live block content is treated as polite by NVDA and its announcement
        do not interrupt current speech resulting in page title announcing after page content will be read. To avoid this behavior focus should be released
        right after been set on 'layout' element. In this case focus will be set to 'body' element which will preserve the same focus/screen reader
        navigation flow as if 'layout' element was focused and will prevent whole page content to be read by NVDA. */
        layoutRef.current.blur();
      }

      activeElRef.current = undefined;
    }
  }, [pageLoadedTime]);

  if (!appLoaded)
    return null;

  if (showLimitedAccessMode)
    return (
      <div id="layout" tabIndex="-1">
        <main id="content" className="limited">
          <LimitedAccess />
        </main>
        <Extras>
          {!isVisualDesigner && <AriaStatus />}
        </Extras>
      </div>
    );

  if (emptyLayout)
    return (
      <div id="layout" tabIndex="-1" ref={layoutRef}>
        <main id="content" className={`minimal page-${routeName}`}>
          {children}
        </main>
        <Extras>
          {!isVisualDesigner && <AriaStatus />}
        </Extras>
      </div>
    );

  if (offline)
    return (
      <div id="layout" tabIndex="-1">
        <main id="content" className="offline">
          <OfflinePage />
        </main>
        <Extras>
          {!isVisualDesigner && <AriaStatus />}
        </Extras>
      </div>
    );

  const topBlockContent = !isVisualDesigner && (
    <>
      <AccountImpersonationBar />
      <NotValidCustomerWarning />
      {!isPrintMode && cookieBarDisplayMode === CookieBarModes.Top && <CookieBar />}
    </>
  );

  const bottomBlockContent = !isVisualDesigner
    && !isPrintMode
    && cookieBarDisplayMode === CookieBarModes.Bottom
    && <CookieBar />;

  return (
    <div id="layout" tabIndex="-1" ref={layoutRef}>
      <LayoutShiftProvider
        topShiftElRef={layoutRef}
        bottomShiftElRef={footerRef}
        topBlockContent={topBlockContent}
        bottomBlockContent={bottomBlockContent}
      >
        <HashRouter>
          <div id="skipLinksContainer" />
          {!isVisualDesigner && (
            <>
              {routeData && <LoadingIndicator />}
              {headerLoaded && <Header isPrintMode={isPrintMode} />}
            </>
          )}
          {isPrintMode && <PrintControls />}
          <main id="content" className={`page-${routeName}`}>
            {children}
          </main>
          {!isVisualDesigner
            ? (
              <>
                {footerLoaded && <Footer ref={footerRef} />}
                <Extras>
                  <AriaStatus />
                  <ProductComparisonWidget />
                  {postForm && <SelfPostingForm formOptions={postForm} />}
                </Extras>
                <Profiler />
                {showScrollTop && <ScrollToTopButton isPrintMode={isPrintMode} />}
                <Modals />
              </>
            )
            : <Extras />
          }
        </HashRouter>
      </LayoutShiftProvider>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  routeData: PropTypes.shape({
    routeName: PropTypes.string.isRequired,
  }),
  isVisualDesigner: PropTypes.bool,
  isPrintMode: PropTypes.bool,
  postForm: PropTypes.object,
  pageLoadedTime: PropTypes.number,
  showScrollTop: PropTypes.bool,
  cookieBarDisplayMode: PropTypes.string,
  limitedAccessMode: PropTypes.bool,
  offline: PropTypes.bool,
  appLoaded: PropTypes.bool,
  emptyLayout: PropTypes.bool,
  headerLoaded: PropTypes.bool,
  footerLoaded: PropTypes.bool,
  hashPresent: PropTypes.bool,
  omitScroll: PropTypes.bool,
  initialScrollPosition: PropTypes.shape({
    value: PropTypes.number,
    screenWidth: PropTypes.number,
  }),
  saveScrollPosition: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { saveScrollPosition })(Layout);

function mapStateToProps({
  routing: { location, routeData },
  page: { isPrintMode, postForm, loadedTime: pageLoadedTime, omitScroll: pageOmitScroll, emptyLayout },
  settings,
  app,
  localization,
  header,
  footer,
}) {
  const showScrollTop = settings.navigation && settings.navigation.scrollTop;
  const cookieBarDisplayMode = settings.cookiebar && settings.cookiebar.mode;
  const { offlineMode, offlineModeSupport, loaded, error, limitedAccessMode } = app;
  const { currentLanguage } = localization;
  const hashPresent = !!location.hash;
  const omitScroll = location.state?.omitScroll || pageOmitScroll;
  const initialScrollPosition = location.state?.initialScrollPosition;

  return {
    routeData,
    isVisualDesigner: isVisualDesigner(location),
    isPrintMode,
    postForm,
    pageLoadedTime,
    showScrollTop,
    cookieBarDisplayMode,
    limitedAccessMode,
    offline: offlineMode && offlineModeSupport === OfflineModeSupport.Disabled,
    appLoaded: error || loaded && (!!currentLanguage.id || currentLanguage.id === undefined), // undefined will be set as language ID in case there was some error.
    emptyLayout,
    headerLoaded: header.loaded,
    footerLoaded: footer.loaded,
    hashPresent,
    omitScroll,
    initialScrollPosition,
  };
}

function isFixedOnScreen(element) {
  while (element !== null || element === document.body) {
    if (window.getComputedStyle(element).position === 'fixed')
      return true;

    element = element.offsetParent;
  }

  return false;
}
