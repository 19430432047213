export const enum UserType {
  Anonymous = 'ANONYMOUS',
  Guest = 'GUEST',
  Registered = 'REGISTERED',
  Admin = 'IMPERSONATING_ADMIN',
}

export const enum CustomerType {
  B2C = 'B2C',
  B2B = 'B2B',
  Sales = 'SALES_AGENT',
}

export const enum AddressValidationStatus {
  Invalid = 'INVALID',
  Suggested = 'SUGGESTED',
  Valid = 'VALID',
}

export const enum ShopAccountType {
  Customer = 'CUSTOMER',
  Contact = 'CONTACT',
  SalesAgent = 'SALES_AGENT',
}

export const enum ShopAccountRole {
  Regular = 'REGULAR',
  Sub = 'SUB',
  Manager = 'MANAGER',
}

export const enum AbilityTo {
  UseWishlist = 'USE_WISHLIST',
  ViewMyAccountPage = 'VIEW_MY_ACCOUNT_PAGE',
  SubscribeToNewsletter = 'SUBSCRIBE_TO_NEWSLETTER',
  ViewPrices = 'VIEW_PRICES',
  ViewStock = 'VIEW_STOCK',
  ViewUnitOfMeasure = 'VIEW_UNIT_OF_MEASURE',
  OrderProducts = 'ORDER_PRODUCTS',
  CreateOrder = 'SUBMIT_ORDER',
  CreateQuote = 'CREATE_QUOTE',
  EditOrders = 'EDIT_ORDERS',
  Reorder = 'REORDER',
  ViewReturnOrders = 'VIEW_RETURN_ORDERS',
  EditProfile = 'EDIT_PROFILE',
  ViewOrders = 'VIEW_ORDERS',
  ViewQuotes = 'VIEW_QUOTES',
  ViewInvoices = 'VIEW_INVOICES',
  ViewReturnReceipts = 'VIEW_RETURN_RECEIPTS',
  ViewCreditNotes = 'VIEW_CREDIT_NOTES',
  ViewShipments = 'VIEW_SHIPMENTS',
  UseSalesAgreements = 'USE_SALES_AGREEMENTS',
  UseOrderTemplates = 'USE_ORDER_TEMPLATES',
  CreateDocFreeReturnOrder = 'CREATE_DOC_FREE_RETURN_ORDER',
  CreateDocBasedReturnOrder = 'CREATE_DOC_BASED_RETURN_ORDER',
  CreateSubAccount = 'CREATE_SUB_ACCOUNT',
  AuthorizeOrders = 'AUTHORIZE_ORDERS',
  CreateProspect = 'CREATE_PROSPECT',
  ViewCatalog = 'VIEW_CATALOG',
  ViewProductSuggestions = 'VIEW_PRODUCT_SUGGESTIONS',
  Impersonate = 'IMPERSONATE',
  CompareProducts = 'COMPARE_PRODUCTS',
  PromoteQuote = 'PROMOTE_QUOTE',
  PayInvoice = 'PAY_INVOICE',
  UseCustomShippingAddress = 'USE_CUSTOM_SHIPPING_ADDRESS',
  ShipToBillingAddress = 'SHIP_TO_BILLING_ADDRESS',
  CheckoutAsGuest = 'CHECKOUT_AS_GUEST',
  AssignAgreementLineAutomatically = 'ASSIGN_AGREEMENT_LINE_AUTOMATICALLY',
}

export const enum AbilityState {
  NotAvailable = 'NOT_AVAILABLE',
  Unauthorized = 'UNAUTHORIZED',
  Available = 'AVAILABLE',
  TemporaryNotAvailable = 'TEMPORARY_NOT_AVAILABLE',
}
